@use 'styles/config' as *;

.ticker {
  position: relative;
  display: flex;
  width: 58.5rem;
  height: 14.6rem;
  background: $color-font;
  max-width: calc(100vw - 3rem);
  overflow: hidden;

  @media (max-width: $max-720) {
    height: 9rem;
    width: 38.5rem;
  }

  &__image {
    &List {
      @include reset-list;
      position: relative;
      flex: 0 0 auto;
      width: 14.6rem;
      height: 14.6rem;
      background: #aaa;

      pointer-events: none;

      overflow: hidden;

      @media (max-width: $max-720) {
        width: 9rem;
        height: 9rem;
      }
    }

    &Item {
      position: absolute 0;
      background: #fff;
    }
  }

  &__list {
    @include reset-list;
    flex: 0 1 100%;
  }

  &__item {
    position: absolute 0;
    pointer-events: none;

    opacity: 0;
  }

  &__title {
    position: absolute;
    top: 2.2rem;
    left: 14.6rem + 3rem;

    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.042;
    @include brandColor(color);

    @media (max-width: $max-720) {
      top: 0.5rem;
      left: 9rem + 2rem;
      font-size: 1rem;
    }
  }

  &__timer {
    position: absolute;
    left: 14.6rem;
    bottom: 0;
    right: 0;
    height: 0.3rem;

    @include brandColor(background, 0.25);

    transform-origin: 0% 100%;
    transition: transform 0.2s;

    @media (max-width: $max-720) {
      left: 9rem;
    }
  }

  &:hover &__timer {
    transform: scale(1, 2);
  }

  $size: 0.3rem;

  &__tick {
    position: relative;

    flex: 0 1 100%;
    height: $size;

    cursor: pointer;

    &::before {
      display: block;
      content: '';

      position: absolute 0;
      top: -1rem;
      bottom: -1rem;

      // background: #000;
    }

    &:not(:first-child) {
      border-left: 0.2rem solid $color-font;
    }

    &List {
      @include reset-list;
      position: absolute 0;

      display: flex;
      z-index: 2;
    }
  }

  &__bar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;

    top: -$size;
    bottom: -$size;

    @include brandColor;

    cursor: pointer;
  }

  &__barWrap {
    position: absolute 0;
    overflow: hidden;
  }

  &__pause {
    @include reset-button;
    @include brandColor(background-color);
    @include copy(1.2rem, 1.4rem);

    position: absolute;
    top: 0;
    right: 0;

    padding: 0.8rem 1rem;

    width: max-content;
    opacity: 0;
    transform: translateY(-100%);

    color: $color-font;
    font-weight: 400;

    &:focus {
      opacity: 1;
      color: $color-font;
      transform: translateY(0);
    }

    svg {
      display: block;
      width: 1.6rem;
    }
  }
}

.tickerItem {
  @include reset-anchor;

  display: flex;
  padding-left: 14.6rem;

  @media (max-width: $max-720) {
    padding-left: 9rem;
  }

  &__content {
    display: -webkit-box;
    margin: 5.1rem 3rem 0;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.44;
    letter-spacing: -0.01em;

    // height: (1.8 * 1.44) * 3rem;
    overflow: hidden;

    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    .hideTitle & {
      margin-top: 3.3rem;
    }

    @media (max-width: $max-720) {
      margin: 3rem 2rem 0 2rem;
      font-size: 1.1rem;

      .hideTitle & {
        margin-top: 2rem;
      }
    }
  }
}
